<template>

    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}系统</div>

        <div class="content" v-loading="loading">
            <el-form  label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="系统名称" prop="systemName">
                    <el-input v-model="form.systemName" placeholder="请输入系统名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="系统编码" prop="systemCode">
                    <el-input v-model="form.systemCode" placeholder="请输入图层名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="系统类型" prop="systemType">
                    <el-radio-group v-model="form.systemType" size="medium">
                        <el-radio label="C/S">C/S</el-radio>
                        <el-radio label="B/S">B/S</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="系统状态" prop="systemStauts">
                    <el-radio-group v-model="form.systemStauts" size="medium">
                        <el-radio label="Y">启用</el-radio>
                        <el-radio label="N">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="单点登录" prop="isSingleLogin">
                    <el-radio-group v-model="form.isSingleLogin" size="medium">
                        <el-radio label="Y">是</el-radio>
                        <el-radio label="N">否</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="url" prop="frontAdress">
                    <el-input v-model="form.frontAdress" placeholder="请输入url" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="backUrl" prop="systemAddress">
                    <el-input v-model="form.systemAddress" placeholder="请输入backUrl" @keyup.enter.native="submit"></el-input>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import mixinsForm from '@/mixins/form'
export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
        CodeEdit: () => import("@/components/CodeEdit.vue"),
        UploadImages: () => import("@/components/upload/Images.vue"),
    },
    mixins: [mixinsForm],
    props: {},
    data() {
        return {
            api:{
                save:this.$api['admin/system'].save,
            },
            rules: {
                systemName: [
                    {required: true, message: '请填写系统名称', trigger: ['blur','change']},
                ],
                systemCode: [
                    {required: true, message: '请填写系统编码', trigger: ['blur','change']},
                ],
                systemType: [
                    {required: true, message: '请选择系统类型', trigger: ['blur','change']},
                ],
                systemStauts: [
                    {required: true, message: '请选择系统状态', trigger: ['blur','change']},
                ],
                isSingleLogin: [
                    {required: true, message: '请选择是否单点登录', trigger: ['blur','change']},
                ],
            },
        };
    },
    computed: {},
    watch: {},
    methods: {
    },
};
</script>
